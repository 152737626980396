import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Socials from '../components/Socials'
import Campaign from "../components/CampaignForm"
import Video from "../components/video"

import Logo from "../images/fc-logo.png"

const FitnessCulture = () => {

  return(
    <Layout>
      <SEO title="Fitness Culture App" description="Get 7 days of the Fitness Culture Swole program for free." />
      <div className="paper py-20 px-5 min-h-screen">
        <div className="container mx-auto my-10">
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 justify-center">
              <img src={Logo} width="150px" className="mx-auto"/>
              <div className="w-full py-10">
                <Video
                  videoSrcURL="https://www.youtube.com/embed/jD2MjhV3xO4"
                  videoTitle="Look Better, Feel Better, Perform Better | Fitness Culture App"
                />
              </div>  
              <p className="font-light">Fitness Culture is my fitness app designed for intermediate and advanced weight lifters and exercise enthusiasts looking
              to take their training to the next level.</p>
              <p className="font-light mt-2">The Fitness Culture app is like having a personal trainer in your pocket. Get access directly to my trainers and coaches, but
              get all the workouts tailored specific to you and your goals.</p>
              <p className="font-light mt-2">Get a free 7 day workout emailed directly to you to get a taste of what Fitness Culture can offer.</p>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center">
            <div className="w-full md:w-1/2 flex justify-center">
              <Campaign campaign="763173021" cta="Get 7 Day Workout" />
            </div>
          </div>
          <div className="flex justify-center">
            <a href="https://www.fitnessculture.com" target="_blank" rel="noopener" className="text-xl">Learn more at FitnessCulture.com</a>
          </div>
          <div className="my-10">
            <Socials />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FitnessCulture